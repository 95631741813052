import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { Image } from '../../Image/Image';
import { ngartImgCntr } from '../../Styles/articleImage';
import { PhotographersLink } from '../../responsive/atoms/PhotographersLink';
import { CoverFlowWrapper } from '../CoverFlowWrapper';
import { theme } from '../../../domain/theme';
import { imageResizerService } from '../../../domain/services/imageResizerService';
import { ParagraphWrapper } from '../ParagraphWrapper';

const ImageCarouselInner = ({ data, bundle, isAboveTheFold }) => {
  const widthSize = data.widthSize || 'medium';
  const {
    images,
    backgroundColor,
    brandedLogo,
    brandedClickThrough,
    heading,
    description
  } = data;

  const fontColor = backgroundColor === 'white' ? 'black' : 'white';

  const styles = {
    caption: css`
      position: relative;
      padding: ${theme.spacing.parse('$s 0')};
      background-color: ${backgroundColor};
      min-height: 60px;
      padding: ${theme.spacing('base')};
      p {
        margin-bottom: 0;
        color: ${fontColor};
        ${theme.font.family('regularWeb')};
        font-weight: 400;
        font-size: 0.875rem;
        letter-spacing: 0.1px;
        line-height: 20px;
      }
      &:after {
        content: '';
        position: absolute;
        width: 30px;
        height: 1px;
        bottom: 0px;
        border-top: 4px solid #ffcc00;
      }
    `,
    captionWhenSelected: css`
      .caption {
        display: block;
      }
    `
  };

  const items = images.map(image => {
    const orientation = imageResizerService.getOrientation(
      image.entity.mediaImage
    );
    const aspects = { landscape: '3x2', portrait: '2x3', square: '1x1' };
    const aspect = aspects[orientation];

    const largeDesktopMaxWidths = {
      small: 315,
      medium: 810,
      large: 1200
    };
    const maxWidths = {
      tablet: widthSize === 'small' ? 400 : 768,
      small_desktop: widthSize === 'small' ? 400 : 810,
      large_desktop: largeDesktopMaxWidths[widthSize]
    };
    // Divide all with by 2 if image is portrait and
    // widthSize is not small.
    if (aspect === '2x3' && widthSize !== 'small') {
      Object.keys(maxWidths).forEach(key => {
        maxWidths[key] /= 2;
      });
    }

    return {
      key: image.entity.id,
      orientation,
      image: image.entity,
      element: (
        <div
          className="ngart-img__cntr"
          css={ngartImgCntr}
          key={`element-${image.entity.id}`}
        >
          <Image
            entity={image.entity}
            aspect={aspect}
            maxWidths={maxWidths}
            loading={isAboveTheFold ? 'eager' : 'lazy'}
            fade={false}
          />
        </div>
      ),
      caption: (
        <div
          css={styles.caption}
          className="caption"
          key={`caption-${image.entity.id}`}
        >
          {image.entity.caption && image.entity.caption !== '' && (
            <div
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: image.entity.caption
              }}
            />
          )}
          <PhotographersLink
            photographers={image.entity.photographers}
            color={{
              text: fontColor,
              link: fontColor
            }}
          />
        </div>
      )
    };
  });
  const branded = { logo: brandedLogo, link: brandedClickThrough?.url?.path };
  return (
    <CoverFlowWrapper
      items={items}
      numberOfVisibleItems={3}
      thumbnailMenuImages={images}
      whenSelectedStyles={styles.captionWhenSelected}
      widthSize={widthSize}
      backgroundColor={backgroundColor}
      branded={branded.logo ? branded : null}
      heading={heading}
      description={description}
      bundle={bundle}
      isAboveTheFold={isAboveTheFold}
    />
  );
};
ImageCarouselInner.defaultProps = {
  bundle: null,
  isAboveTheFold: false
};

ImageCarouselInner.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  bundle: PropTypes.string,
  isAboveTheFold: PropTypes.bool
};

export const ImageCarousel = ParagraphWrapper(ImageCarouselInner);
